<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }">
        <div class="modal_container">
            <div class="modal_header border">
                <div class="modal_info">
                    <h1>Switch Tabs</h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="setting_wpr">
                    <Form @submit="handleSwitchTab" v-slot="{ errors }">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Tabs</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.tabs }">
                                    <Field autocomplete="off" name="tabs" v-model="form.status" rules="required">
                                        <multiselect
                                            v-model="form.status"
                                            :groups="true"
                                            :searchable="true"
                                            :options="statusesGroup"
                                            valueProp="value"
                                            label="title"
                                            placeholder="Select status"
                                            :group-select="false"
                                            group-label="type"
                                            group-options="items"
                                            :group-hide-empty="true"
                                            :loading="statusTabsLoader"
                                        >
                                            <template v-slot:grouplabel="{ group }">
                                                <span class="status-tabs-header">{{ group.type }}</span>
                                            </template>
                                            <template v-slot:option="{ option }">
                                                <span>{{ option.title }}</span>
                                            </template>
                                        </multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage name="tabs" class="text-danger" />
                            </div>
                        </div>
                        <schedule-component :schedule-data="form" :close-modal="closeModal" title="status" />
                        <div class="action_wpr mt-5">
                            <button :disabled="bulkActionLoader" type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                            <button :disabled="bulkActionLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="bulkActionLoader"></i>{{ bulkActionLoader ? ' Changing' : 'Change' }}</button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    const ScheduleComponent = defineAsyncComponent(() => import('@/components/Schedule'))

    export default {
        name: 'SwitchTab',

        data () {
            return {
                form: {
                    status: '' ,
                    status_title: '' ,
                    contacts: [],
                    action: '/contacts/update-contacts-status',
                },
            }
        },

        props: {
            modelValue: Boolean,
            contacts: [Object, Array],
            status: {
                type: String,
                default: ''
            },
        },

        emits: ['update:modelValue'],

        components: {
            Form,
            Field,
            ErrorMessage,
            ScheduleComponent,
        },

        watch: {
            'form.status' (formStatus) {
                const vm = this;

                if (formStatus) {
                    const status = Object.values(vm.statusList).find(status => status.value ===  vm.form.status);

                    vm.form.status_title = status ? status.title : '';
                }
            },

            modelValue (val) {
                if (val == true) {
                    const vm = this;

                    vm.resetForm();
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },
        },

        computed: mapState({
            statusesGroup: state => state.contactModule.statusesGroup,
            statusList: state => state.contactModule.statusList,
            bulkActionLoader: state => state.contactModule.bulkActionLoader,
            statusTabsLoader: state => state.contactModule.statusTabsLoader,
        }),

        methods: {
            ...mapActions({
                changeStatusTab: 'contactModule/changeStatusTab',
            }),

            closeModal () {
                const vm = this;

                vm.form.status = '';
                vm.$emit('update:modelValue', false);
            },

            handleSwitchTab (form, { setFieldError }) {
                const vm = this;

                vm.form.setFieldError = setFieldError;
                vm.form.closeModal = vm.closeModal;

                vm.changeStatusTab(vm.form);
            },

            resetForm () {
                const vm = this;

                vm.form.contacts = vm.contacts;
                vm.form.status   = vm.status;
            }
        },
    }
</script>
